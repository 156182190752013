<template>
  <main class="new-step-container">
    <div class="main-title" v-if="isEdit">Edit Step</div>
    <div class="main-title" v-else>New Step</div>
    <div class="new-step-content">
      <div class="layouts">
        <div class="title">Layouts</div>
        <div class="list">
          <div
            class="layout"
            v-for="(layout, index) in layouts"
            :key="index"
            :class="{ active: isActive === layout.title }"
            @click="isActive = layout.title"
          >
            <div class="layout-title">{{ layout.title }}</div>
            <div class="layout-description">{{ layout.description }}</div>
          </div>
        </div>
      </div>
      <div ref="main" class="new-step">
        <div class="header">
          <span class="title" v-if="step_title.length">{{ step_title }}</span>
          <span class="title" v-else>{{ editStepInfo.title }}</span>
          <div class="options">
            <div class="preview" @click="handlePreview">Step Preview</div>
            <div v-if="isEdit" class="save" @click="updateData">Save All</div>
            <div v-else class="save" @click="saveData">Save All</div>
          </div>
        </div>
        <div class="step-params">
          <div class="base">
            <div class="title-param">
              <div class="label">Title</div>
              <input
                ref="stepTitle"
                v-model="step_title"
                type="text"
                placeholder="Step Title"
              />
            </div>
            <div class="descrpition-param">
              <div class="label">Description</div>
              <textarea
                ref="stepDescription"
                v-if="isEdit"
                placeholder="Step Description"
                :value="editStepInfo.description"
                maxlength="250"
              ></textarea>
              <textarea
                ref="stepDescription"
                v-else
                placeholder="Step Description"
                maxlength="250"
              ></textarea>
            </div>
            <div v-if="isActive === 'Image Gallery'" class="gallery-param">
              <div class="label">Image Gallery</div>
              <div class="gallery-container">
                <div
                  class="image-preview"
                  v-for="(image, index) in galleryImages"
                  :key="index"
                  :title="image.name"
                  @mouseover="hoveredImg = index"
                  @mouseleave="hoveredImg = undefined"
                >
                  <img :src="image.link" />
                  <transition name="fade">
                    <div
                      class="hover"
                      v-if="hoveredImg === index"
                      @click="removeImage(index)"
                    >
                      <div class="del">
                        <img src="/assets/images/delete.svg" alt="" />
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="import-image" ref="impBtn">
                  <label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      @change="importImage"
                    />
                    +
                  </label>
                </div>
              </div>
            </div>
            <div v-if="isActive === 'Hotspots'" class="hotspots-param">
              <div class="label">Hotspot Tooltips</div>
              <div class="hotspot-container">
                <div
                  v-if="!hotspotImage.length"
                  class="import-image"
                  ref="impBtn"
                >
                  <label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      @change="importHotspot"
                    />
                    +
                  </label>
                </div>
                <div v-else class="hotspot-image">
                  <img
                    ref="hotspotImage"
                    :src="hotspotImage[0].link"
                    @click="drawMarker"
                  />
                  <div
                    class="delete"
                    title="delete image"
                    @click="removeHsImage"
                  >
                    <img src="/assets/images/delete.svg" alt="" />
                  </div>
                  <template v-if="markers.length">
                    <template
                      v-for="(marker, index) in markers"
                      :key="marker.number"
                    >
                      <div
                        class="hotspot"
                        :id="marker.number"
                        :style="{
                          left: marker.position
                            ? marker.position.left + 'px'
                            : '0px',
                          top: marker.position
                            ? marker.position.top + 'px'
                            : '0px',
                          background: marker.color,
                        }"
                        @mousedown="drag = true"
                        @mousemove="drag ? handleMouseDrag(index, $event) : ''"
                        @mouseup="drag = false"
                        :title="marker.description"
                      >
                        {{ marker.number || marker.sequence }}
                      </div>
                      <div
                        class="hotspot-name"
                        v-if="marker.description === ''"
                        :style="{
                          left: marker.position.left - 100 + 15 + 'px',
                          top: marker.position.top - 30 + 'px',
                        }"
                      >
                        <input
                          type="text"
                          placeholder="Insert hotspot name here"
                          :id="`input-${marker.number}`"
                        />
                        <div
                          class="submit-name"
                          @click="saveName(index, marker.number)"
                        >
                          ✓
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
              <template v-if="markers.length">
                <div
                  class="markers"
                  v-for="(marker, index) in markers"
                  :key="marker.number"
                  @mouseover="!editName ? handleHover(marker.number) : ''"
                  @mouseout="handleHoverOut"
                >
                  <span
                    class="hs-id"
                    :style="{ borderRight: `solid 2px ${marker.color}` }"
                    >{{ marker.number || marker.sequence }}</span
                  >
                  <span
                    class="marker-name"
                    v-if="!marker.edit"
                    @click="marker.edit = true"
                    >{{ marker.description }}</span
                  >
                  <div v-else class="edit-marker">
                    <div class="inputs">
                      <label :for="`name-${id}`">Name: </label>
                      <input
                        type="text"
                        :value="marker.description"
                        :id="`name-${marker.number}`"
                      />
                      <label :for="`color-${id}`">Color: </label>
                      <input
                        type="color"
                        :value="marker.color"
                        :id="`color-${marker.number}`"
                      />
                    </div>
                    <div class="submit-changes">
                      <span
                        @click="editMarker(index, marker.number)"
                        class="confirm"
                        >✓</span
                      >
                      <span @click="marker.edit = false" class="cancel">✗</span>
                    </div>
                  </div>
                  <span
                    class="delete-marker"
                    v-if="!marker.edit"
                    @click="deleteHotspot(index)"
                  >
                    <img src="/assets/images/delete.svg" alt="" />
                  </span>
                </div>
              </template>
            </div>
          </div>
          <div class="extra">
            <div class="documents">
              <div class="title">Documents</div>
              <div class="current-files">
                <template v-if="documents.length">
                  <div
                    class="document"
                    v-for="(document, index) in documents"
                    :key="index"
                  >
                    <div class="info">
                      <div class="icon">
                        <img src="/assets/images/doc-icon.svg" alt="" />
                      </div>
                      <div
                        class="doc-info"
                        :title="document.name"
                        @click="openDoc(document)"
                      >
                        <div class="name">{{ document.name }}</div>
                        <div class="extras">
                          <span>{{ document.pages }} pages</span>
                          <span>{{ document.size }}</span>
                          <span>{{ document.extension }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="options">
                      <span
                        class="dots"
                        @click="document.edit = !document.edit"
                      >
                        <img src="/assets/images/black_dots.svg" />
                        <options-modal
                          v-if="document.edit"
                          v-click-outside="handleDocOutside"
                          :options="[]"
                          @delete="del('doc', index)"
                        />
                      </span>
                      <span class="date">{{ document.imported_at }}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="no-docs">
                    <div class="circle">
                      <img src="/assets/images/doc-icon.svg" alt="" />
                    </div>
                    <div class="info">No documents were added yet</div>
                  </div>
                </template>
              </div>
              <div class="upload">
                <div class="import-doc" ref="impDoc">
                  <label>
                    <input
                      ref="doc"
                      type="file"
                      accept=".pdf, text/plain"
                      @change="importDocument"
                    />
                    Upload New File
                  </label>
                </div>
              </div>
            </div>
            <div class="measurements">
              <div class="title">Measurements</div>
              <div
                class="current-measurements"
                :class="{ create: createMeasurement }"
              >
                <template v-if="!createMeasurement && measurements.length">
                  <div
                    class="measurement"
                    v-for="(measurement, index) in measurements"
                    :key="index"
                  >
                    <div class="info" :title="measurement.name">
                      <div class="value">
                        {{ measurement.value }} {{ measurement.scale }}
                      </div>
                      <div class="extras">
                        <span>{{ measurement.variable }}</span>
                        <span>{{ measurement.name }}</span>
                      </div>
                    </div>
                    <div class="options">
                      <span
                        class="dots"
                        @click="measurement.edit = !measurement.edit"
                      >
                        <img src="/assets/images/black_dots.svg" />
                        <options-modal
                          v-if="measurement.edit"
                          v-click-outside="handleMeasureOutside"
                          :options="['duplicate']"
                          @duplicate="duplicate('measure', index)"
                          @delete="del('measure', index)"
                        />
                      </span>
                      <span class="date">{{ measurement.imported_at }}</span>
                    </div>
                  </div>
                </template>
                <template v-else-if="createMeasurement">
                  <div class="create-measurement">
                    <div class="add-title">Measurements</div>
                    <div class="data">
                      <div class="name">
                        <label for="m-name">Name</label>
                        <input
                          ref="mName"
                          type="text"
                          name="m-name"
                          placeholder="Insert name here"
                        />
                      </div>
                      <div class="variable">
                        <label for="m-variable">Variable</label>
                        <select
                          ref="variable"
                          name="variable"
                          @change="handleVariable"
                          required
                        >
                          <option disabled selected>Please select one</option>
                          <option
                            v-for="(variable, index) in variables"
                            :key="index"
                            :value="index"
                          >
                            {{ variable.name }}
                          </option>
                        </select>
                      </div>
                      <div class="scale">
                        <label for="m-scale">Scale</label>
                        <select ref="scale" name="scale" required>
                          <option disabled selected>Please select one</option>
                          <template v-if="scales">
                            <option
                              v-for="(scale, index) in scales"
                              :key="index"
                              :value="scale.symbol"
                            >
                              {{ scale.name }} ({{ scale.symbol }})
                            </option>
                          </template>
                        </select>
                      </div>
                      <div class="value">
                        <label for="m-value">Value</label>
                        <input
                          ref="mValue"
                          type="number"
                          name="m-value"
                          placeholder="Insert value here"
                        />
                      </div>
                      <div class="notes">
                        <div class="container">
                          <label>Notes</label>
                          <label class="switch">
                            <input ref="mNotes" type="checkbox" checked />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="no-docs">
                    <div class="circle">
                      <img src="/assets/images/measurements.svg" alt="" />
                    </div>
                    <div class="info">No measurements were added yet</div>
                  </div>
                </template>
              </div>
              <div class="upload">
                <div
                  class="new-measurement"
                  v-if="!createMeasurement"
                  @click="createMeasurement = true"
                >
                  <span>Add new measurement</span>
                </div>
                <div class="new-measurement" v-else @click="handleMeasurement">
                  <span>Confirm</span>
                </div>
              </div>
            </div>
            <div class="measurements">
              <div class="title">Tools</div>
              <div
                class="current-measurements"
                :class="{ create: createTool }"
              >
                <template v-if="!createTool && tools.length">
                  <div
                    class="measurement"
                    v-for="(tool, index) in tools"
                    :key="index"
                  >
                    <div class="info" :title="tool.name">
                      <div class="value">
                        {{ tool.name }}
                      </div>
                    </div>
                    <div class="options">
                      <span
                        class="dots"
                        @click="tool.edit = !tool.edit"
                      >
                        <img src="/assets/images/black_dots.svg" />
                        <options-modal
                          v-if="tool.edit"
                          v-click-outside="handleToolOutside"
                          @delete="del('tool', index)"
                        />
                      </span>
                      <span class="date">{{ tool.imported_at }}</span>
                    </div>
                  </div>
                </template>
                <template v-else-if="createTool">
                  <div class="create-measurement">
                    <div class="data">
                      <div class="value">
                        <label for="m-value">Tool</label>
                        <input
                          v-model="newTool"
                          placeholder="Insert tool here"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="no-docs">
                    <div class="circle">
                      <img src="/assets/images/measurements.svg" alt="" />
                    </div>
                    <div class="info">No tools were added yet</div>
                  </div>
                </template>
              </div>
              <div class="upload">
                <div
                  class="new-measurement"
                  v-if="!createTool"
                  @click="createTool = true"
                >
                  <span>Add new tool</span>
                </div>
                <div class="new-measurement" v-else @click="handleTool">
                  <span>Confirm</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <preview
        v-if="seePreview"
        :type="'step'"
        :info="stepInfo"
        @close="seePreview = false"
      />
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import { mapMutations } from "vuex";
import axiosInstance from "@/services/AxiosTokenInstance";
import optionsModal from "@/components/Ui/General/Options.vue";
import Preview from "@/components/previews/Preview.vue";
import { CREATE_STEP_ACTION } from "@/store/storeconstants";
import layouts from "@/assets/json/layouts.json";
import variables from "@/assets/json/variables.json";

import { LOADING_SPINNER_SHOW_MUTATION } from "@/store/storeconstants";
export default {
  emits: ["close"],
  props: {
    isEdit: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step_title: "",
      title: "",
      description: "",
      errors: [],
      serverError: "",
      result: "",
      disabled: false,
      isCreateStepModalVisible: false,
      isCreateImageModalVisible: false,
      isActive: layouts[0].title,
      layouts,
      galleryImages: [],
      hotspotImage: [],
      editHotspotImage: [],
      documents: [],
      deletedDocs: [],
      newDocuments: [],
      measurements: [],
      tools: [],
      hoveredImg: undefined,
      markers: [],
      drag: false,
      editName: false,
      createMeasurement: false,
      createTool: false,
      newMeasurement: [],
      variables,
      scales: [],
      seePreview: false,
      stepInfo: {},
      editStepInfo: {},
      imgURL: process.env.VUE_APP_DO_SPACES,
      id_organization: undefined,
      replacedMarkers: false,
      newTool: undefined,
      newTools: [],
      deletedTools: []
    };
  },
  components: {
    optionsModal,
    Preview,
  },

  watch: {
    isActive() {
      setTimeout(() => {
        this.buttonHeight();
      }, 1);
    },

    createMeasurement(val) {
      if (val) {
        this.scales = [];
      }
    },
  },

  mounted() {
    this.buttonHeight();
    window.addEventListener(
      "resize",
      () => {
        this.buttonHeight();
      },
      true
    );

    if (this.isEdit) this.getStepDetails();
    else this.getGuideDetails();

    let resizeObserver = new ResizeObserver(() => {
      this.replaceMarkers();
    });

    resizeObserver.observe(this.$refs.main);
  },

  methods: {
    async getStepDetails(value) {
      this.stepId = this.$route.params.stepid;
      this.showLoading(true);
      this.currentPage = value;
      this.stepId = this.$route.params.stepid;
        let response = await axiosInstance.get(`step/${this.stepId}`);
        let responseData = response.data;
        this.guideId = responseData.data.id_troubleshoot;
        this.editStepInfo = responseData.data;
        this.step_title = this.editStepInfo.title;

        this.pathing([
          { name: "categories", link: "/equipment/guides" },
          {
            name: `Guides for ${this.editStepInfo.troubleshoot.category.name}`,
            link: `/equipment/guides/${this.editStepInfo.troubleshoot.category.id}`,
          },
          {
            name: this.editStepInfo.troubleshoot.title,
            link: `/equipment/guides/${this.editStepInfo.troubleshoot.category.id}/step-list/${this.editStepInfo.troubleshoot.id}`,
          },
          { name: this.editStepInfo.title },
        ]);

        if (this.editStepInfo.documents) {
          this.editStepInfo.documents.forEach((element) => {
            this.documents.push({
              id: element.id,
              name: element.name,
              size: element.size,
              pages: "?",
              extension: element.file_type,
              link: element.file,
              imported_at: element.created_at.split("T")[0],
            });
          });
        }

        if (this.editStepInfo.measurement.length) {
          this.editStepInfo.measurement.forEach((element) => {
            this.measurements.push({
              name: element.name,
              variable: element.variable,
              scale: element.scale,
              value: element.value,
              notes: element.notes === "1" ? true : false,
              edit: false,
            });
          });
        }

        if (this.editStepInfo.tools.length) {
          this.editStepInfo.tools.forEach((element) => {
            this.tools.push({
              name: element.name,
              id: element.id,
              edit: false,
            });
          });
        }
        
        this.perPage = responseData.per_page;
        this.total = responseData.no_of_records;
        this.markers = this.editStepInfo.hotspots || [];
        if (this.markers.length)
          this.markers.forEach((el) => (el.position = JSON.parse(el.position)));
        this.hotspotImage = this.editStepInfo.images;
        if (this.hotspotImage.length)
          this.hotspotImage.forEach((el) =>
            el.image ? (el.link = this.imgURL + el.image) : ""
          );
        this.showLoading(false);
    },

    async getGuideDetails() {
      this.guideId = this.$route.params.guideid;
      let response = await axiosInstance.get(`show-step/${this.guideId}`);
      const res = response.data.data;
      this.id_organization = res.category_details.id_organization;
      this.pathing([
        { name: "categories", link: "/equipment/guides" },
        {
          name: `Guides for ${res.category_details.name}`,
          link: `/equipment/guides/${res.category_details.id}`,
        },
        {
          name: res.trouble_shoot_title,
          link: `/equipment/guides/${res.category_details.id}/step-list/${res.trouble_shoot_id}`,
        },
        { name: "new step" },
      ]);
    },

    handleMeasureOutside() {
      this.measurements.forEach((el) => (el.edit = false));
    },
    handleToolOutside() {
      this.tools.forEach((el) => (el.edit = false));
    },
    handleDocOutside() {
      this.documents.forEach((el) => (el.edit = false));
    },

    objectSize(obj) {
      var size = 0,
        key;
      for (key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    },

    buttonHeight() {
      const impBtn = this.$refs.impBtn;
      if (impBtn) {
        const rect = impBtn.getBoundingClientRect();
        impBtn.style.height = rect.width + "px";
      }
    },

    close() {
      this.$emit("close");
    },

    ...mapActions("troubleshoot", {
      createNewstep: CREATE_STEP_ACTION,
    }),

    ...mapMutations({
      showLoading: LOADING_SPINNER_SHOW_MUTATION,
      pathing: "pathing/setPathing",
    }),
    

    importImage(event) {
      const file = event.target.files[0];

      if (FileReader && file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          this.galleryImages.push({
            name: file.name,
            link: fr.result,
            size: file.size,
            originalFile: file,
          });
        };
      }
    },

    importHotspot(event) {
      const file = event.target.files[0];

      if (FileReader && file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          if (Object.keys(this.editStepInfo).length)
            this.editHotspotImage.push({
              name: file.name,
              link: fr.result,
              originalFile: file,
              size: this.bytesToSize(file.size),
            });
          this.hotspotImage.push({
            name: file.name,
            link: fr.result,
            originalFile: file,
            size: this.bytesToSize(file.size),
          });
        };
      }
    },

    removeImage(index) {
      this.galleryImages.splice(index, 1);
    },

    removeHsImage() {
      this.hotspotImage = [];
      this.editHotspotImage = [];
      this.markers = [];
      setTimeout(() => {
        this.buttonHeight();
      }, 1);
    },

    drawMarker(mouse) {
      const htsp = document.querySelectorAll(".hotspot");
      const canvas = this.$refs.hotspotImage;

      const rect = canvas.getBoundingClientRect();
      const mouseXPos = mouse.x - rect.left;
      const mouseYPos = mouse.y - rect.top;
      let markerName = "";

      const hsId = htsp ? htsp.length + 1 : "1";

      this.markers.push({
        number: hsId,
        step_id: this.stepId,
        position: {
          left: mouseXPos,
          top: mouseYPos,
          relativeTo: {
            height: rect.height,
            width: rect.width,
          },
        },
        description: markerName,
        color: "var(--primary-color)",
        edit: false,
      });

      this.replacedMarkers = true;
    },

    handleHover(id) {
      const all = document.querySelectorAll(".hotspot");

      all.forEach((el) => {
        if (!el.id.includes(id)) {
          el.style.opacity = 0.3;
        }
      });
    },
    handleHoverOut() {
      const all = document.querySelectorAll(".hotspot");

      all.forEach((el) => (el.style.opacity = 1));
    },

    handleMouseDrag(id, mouse) {
      const canvas = this.$refs.hotspotImage;
      const rect = canvas.getBoundingClientRect();
      const mouseXPos = mouse.x - rect.left;
      const mouseYPos = mouse.y - rect.top;

      if (this.markers[id].position) {
        this.markers[id].position.left = mouseXPos;
        this.markers[id].position.top = mouseYPos;
        this.markers[id].position.relativeTo.height = rect.height;
        this.markers[id].position.relativeTo.width = rect.width;
      } else {
        this.markers[id].position = {
          left: mouseXPos,
          top: mouseYPos,
          relativeTo: {
            height: rect.height,
            width: rect.width,
          },
        };
      }

      this.replacedMarkers = true;
    },

    saveName(index, id) {
      this.markers[index].description = document.getElementById(
        `input-${id}`
      ).value;
    },

    editMarker(index, id) {
      const name = document.getElementById(`name-${id}`).value;
      const color = document.getElementById(`color-${id}`).value;

      this.markers[index].description = name;
      this.markers[index].color = color;

      this.markers[index].edit = false;

      this.replacedMarkers = true;
    },

    deleteHotspot(index) {
      this.markers.splice(index, 1);
      for (let i = 1; i - 1 < this.markers.length; i++) {
        const element = this.markers[i - 1];
        element.number = i;
      }
    },

    bytesToSize(bytes) {
      const sizes = ["bytes", "kb", "mb", "gb", "tb"];
      if (bytes == 0) return "0 Byte";
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    importDocument(e) {
      const file = e.target.files[0];
      let filePages = 0;
      const today = new Date();
      const date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();

      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onloadend = () => {
        filePages = reader.result.match(/\/Type[\s]*\/Page[^s]/g)
          ? reader.result.match(/\/Type[\s]*\/Page[^s]/g).length
          : "NaN";
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (Object.keys(this.editStepInfo).length) {
            this.newDocuments.push({
              mime: file.type,
              data: reader.result,
              size: this.bytesToSize(file.size),
              pages: filePages,
            });
          }

          this.documents.push({
            name: file.name,
            pages: filePages,
            size: this.bytesToSize(file.size),
            extension: file.name.split(".")[1].toUpperCase(),
            imported_at: date,
            link: reader.result,
            edit: false,
            og_doc: file,
          });

          this.$refs.doc.value = "";
        };
      };
    },

    handleMeasurement() {
      this.measurements.push({
        name: this.$refs.mName.value,
        variable: this.variables[this.$refs.variable.value].name,
        scale: this.$refs.scale.value,
        value: this.$refs.mValue.value,
        notes: this.$refs.mNotes.checked,
        edit: false,
      });

      this.createMeasurement = false;
    },

    handleTool() {
        this.tools.push({
            name: this.newTool,
            edit: false,
        })

        this.newTools.push({
            name: this.newTool,
            edit: false,
        })

        this.createTool = false;
        this.newTool = undefined
    },

    handleVariable(e) {
      this.scales = this.variables[e.target.value].scales;
    },

    openDoc(document) {
      if (document.og_doc) {
        let fileWindow = window.open("");
        fileWindow.document.write(`
						<html>
						<head>
						<title>
							${document.name}
						</title>
						</head>
						<body style="margin: 0 auto;">
							<embed width='100%' height='100%' src="${document.link}" />
						</body>
						</html>`);
      } else window.open(this.imgURL + document.link);
    },

    duplicate(what, index) {
      switch (what) {
        case "measure":
          this.measurements.push({
            name: this.measurements[index].name,
            variable: this.measurements[index].variable,
            scale: this.measurements[index].scale,
            value: this.measurements[index].value,
            notes: this.measurements[index].notes,
            edit: false,
          });
          this.measurements[index - 1].edit = false;
          break;
      }
    },

    del(what, index) {
      switch (what) {
        case "tool":
          this.tools[index].edit = false;
          console.log(this.tools)
          this.deletedTools.push(this.tools[index])
          this.tools.splice(index, 1);
          break;
        case "measure":
          this.measurements[index].edit = false;
          this.measurements.splice(index, 1);
          break;
        case "doc":
          this.documents[index].edit = false;
          if (
            Object.keys(this.editStepInfo).length &&
            this.editStepInfo.docu_list.length
          ) {
            if (
              this.editStepInfo.docu_list
                .map((el) => el.id)
                .includes(this.documents[index].id)
            )
              this.deletedDocs.push(this.documents[index].id);
            else if (
              this.newDocuments
                .map((el) => el.data)
                .includes(this.documents[index].link)
            ) {
              this.newDocuments.splice(
                this.newDocuments
                  .map((el) => el.data)
                  .indexOf(this.documents[index].link),
                1
              );
            }
          }
          this.documents.splice(index, 1);
          break;
      }
    },

    handlePreview() {
      this.stepInfo = {
        type: "step",
        title: this.$refs.stepTitle.value,
        description: this.$refs.stepDescription.value,
        extras: {
          image: {
            info: this.hotspotImage,
            hotspots: this.markers,
          },
          files: this.documents,
          measurements: this.measurements,
        },
      };
      this.seePreview = true;
    },

    async saveData() {
      let finalImage = [];
      if (this.hotspotImage.length) {
        this.hotspotImage.forEach((element) => {
          finalImage.push({
            image_file: {
              mime: element.originalFile.type,
              data: element.link.split(',')[1],
              size: element.size
            },
            hotspots: this.markers,
          });
        });
      }

      let finalDocs = [];
      if (this.documents.length) {
        this.documents.forEach((element) => {
          finalDocs.push({
            mime: element.og_doc.type,
            data: element.link,
            size: element.size,
            pages: '2',
          });
        });
      }

      const org = JSON.parse(localStorage.getItem("organization"));
      let bodyFormData = {
        id_organization: org.id,
        id_troubleshoot: Number(this.$route.params.guideid),
        title: this.step_title,
        description: this.$refs.stepDescription.value,
        image: finalImage,
        documents: finalDocs,
        measurements: this.measurements,
        tools: this.tools,
        status: 1
      };

      let response = await axiosInstance.post("create-troubleshoot-step", bodyFormData);
      if (response.status == 200) {
        this.$router.push({
          path: `/equipment/guides/${this.$route.params.catid}/step-list/${this.guideId}`,
        });
        this.emitter.emit('alert', 'Step created successfuly')
      }
    },

    async updateData() {
      let bodyFormData = {};

      let finalImage = [];
      if (this.editHotspotImage.length) {
        this.editHotspotImage.forEach((element) => {
          finalImage.push({
            image_file: {
              mime: element.originalFile.type,
              data: element.link.split(",")[1],
              size: element.size,
            },
            hotspots: this.markers,
          });
        });
        bodyFormData.image = finalImage;
      }

      bodyFormData.delete_tools = this.deletedTools.map(el => el.id).filter(el => el)
      bodyFormData.create_tools = this.newTools.filter(el => el.name)

      if (this.replacedMarkers && !this.editHotspotImage.length) {
        bodyFormData.image = [
          {
            id: this.hotspotImage[0].id,
            image_file: {},
            hotspots: this.markers.map((el) => {
              return {
                number: el.number || el.sequence,
                position: el.position,
                description: el.description,
                color: el.color,
              };
            }),
          },
        ];
      }

      let finalDocs = [];
      if (this.newDocuments.length) {
        this.newDocuments.forEach((element) => {
          finalDocs.push({
            mime: element.mime,
            data: element.data.split(",")[1],
            size: element.size,
            pages: element.pages,
          });
        });

        bodyFormData.documents = finalDocs;
      }

      if (this.deletedDocs.length)
        bodyFormData.deleted_documents = this.deletedDocs;

      if (this.measurements.length)
        bodyFormData.measurements = this.measurements;

      if (this.step_title !== this.editStepInfo.title) {
        bodyFormData.title = this.step_title;
        bodyFormData.name = this.step_title;
      }

      if (this.$refs.stepDescription.value !== this.editStepInfo.description)
        bodyFormData.description = this.$refs.stepDescription.value;

      bodyFormData.step_id = this.$route.params.stepid;

      let response = await axiosInstance.post("update-troubleshoot-step", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status == 200) {
        this.$router.push({
          path: `/equipment/guides/${this.$route.params.catid}/step-list/${this.guideId}`,
        });
        this.emitter.emit('alert', 'Step updated successfuly')
      }
    },

    replaceMarkers() {
      if (this.markers.length) {
        const canvas = this.$refs.hotspotImage;
        if (canvas) {
          const rect = canvas.getBoundingClientRect();
          this.markers.forEach((marker) => {
            marker.position.left =
              (marker.position.left * rect.width) /
              marker.position.relativeTo.width;
            marker.position.top =
              (marker.position.top * rect.height) /
              marker.position.relativeTo.height;
            marker.position.relativeTo.width = rect.width;
            marker.position.relativeTo.height = rect.height;
          });
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.new-step-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-step-container .main-title {
  font-weight: bold;
  font-size: 1.2rem;
}
.new-step-container .new-step-content {
  display: flex;
  gap: 20px;
  height: 80vh;
  position: relative;
}
.new-step-container .new-step-content .layouts,
.new-step-container .new-step-content .new-step {
  background: white;
  border-radius: 5px;
}

.new-step-container .new-step-content .layouts {
  width: 20%;
}

.new-step-container .new-step-content .layouts .title {
  background: var(--primary-color);
  padding: 15px 25px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  height: 50px;
}

.new-step-container .new-step-content .layouts .list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 20px;
}

.new-step-container .new-step-content .layouts .list .layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: #f2f5f8;
  border: 1px solid #0515241f;
  border-radius: 5px;
  padding: 25px 50px;
  user-select: none;
  cursor: pointer;
  transition: 0.2s;
}

.new-step-container .new-step-content .layouts .list .layout:hover {
  background: #0072FF11;
}

.new-step-container .new-step-content .layouts .list .active {
  background: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
}

.new-step-container .new-step-content .layouts .list .active.layout:hover {
    color: black;
}

.new-step-container .new-step-content .layouts .list .layout .layout-title {
  font-weight: bold;
}

.new-step-container
  .new-step-content
  .layouts
  .list
  .layout
  .layout-description {
  text-align: center;
  font-size: 0.8rem;
}

.new-step-container .new-step-content .new-step {
  width: 80%;
}

.new-step-container .new-step-content .new-step {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.new-step-container .new-step-content .new-step .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-shadow: 0px 1px 8px #142e6e1a;
  height: 50px;
}

.new-step-container .new-step-content .new-step .header .title {
  font-weight: 500;
  font-size: 1.2rem;
}

.new-step-container .new-step-content .new-step .header input {
  border: 1px solid #d4dee7;
  width: 30%;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: bold;
}

.new-step-container .new-step-content .new-step .header input::placeholder {
  font-weight: normal;
}

.new-step-container .new-step-content .new-step .header .options {
  display: flex;
  gap: 50px;
}
.new-step-container .new-step-content .new-step .header .options div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
}
.new-step-container .new-step-content .new-step .header .options .save {
  background: var(--primary-color);
  color: white;
  padding: 10px 50px;
  border-radius: 30px;
  transition: 0.2s;
}
.new-step-container .new-step-content .new-step .header .options .save:hover {
  background: #1d4dbd;
}
.new-step-container .new-step-content .new-step .header .options .preview {
  color: var(--primary-color);
}
.new-step-container
  .new-step-content
  .new-step
  .header
  .options
  .preview:hover {
  color: #1d4dbd;
}
.new-step-container .new-step-content .new-step .step-params {
  display: flex;
  padding: 25px 20px;
  width: 100%;
  height: 100%;
  user-select: none;
  overflow-y: auto;
}
.new-step-container .new-step-content .new-step .step-params .base {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 70%;
}
.new-step-container .new-step-content .new-step .step-params .base div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .descrpition-param
  textarea {
  resize: none;
  height: 200px;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .descrpition-param
  textarea:focus {
  outline: none;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container
  div {
  width: 19%;
  overflow: hidden;
  border: 1px solid #0515241f;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container
  .image-preview
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container
  .image-preview
  .hover {
  background: var(--primary-color);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container
  .image-preview
  .hover
  .del {
  width: 30%;
  height: 30%;
  background: white;
  border-radius: 50%;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container
  .image-preview
  .hover
  .del
  img {
  width: 50%;
  height: auto;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container {
  margin-bottom: 20px;
  position: relative;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container
  .hotspot-image
  .delete {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #0515241f;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container
  .hotspot-image
  .delete:hover {
  background: #29333b0e;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container
  .hotspot-image
  .delete
  img {
  width: 60%;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container
  .import-image,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container
  .import-image {
  background: #f2f5f8;
  color: var(--primary-color);
  font-size: 3rem;
  height: 50px;
  transition: 0.2s;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container
  .import-image {
  width: 19%;
  overflow: hidden;
  border: 1px solid #0515241f;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container
  .import-image
  label,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container
  .import-image
  label,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .upload
  .import-doc
  label {
  margin: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container
  .import-image
  label
  input,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container
  .import-image
  label
  input,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .upload
  .import-doc
  label
  input {
  display: none;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container
  .import-image:hover,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container
  .import-image:hover {
  background: var(--primary-color);
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .descrpition-param
  textarea,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .gallery-param
  .gallery-container,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspots-param
  .hotspot-container,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .title-param
  input {
  border: 1px solid #cad6e2;
  width: 65%;
  padding: 10px;
  border-radius: 5px;
}

.hotspot {
  user-select: none;
  position: absolute;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspot-name {
  position: absolute;
  width: 200px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background: white;
  border-radius: 3px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspot-name
  input {
  background: transparent;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .hotspot-name
  .submit-name {
  user-select: none;
  border: solid 1px black;
  border-radius: 3px;
  padding: 0 5px;
  cursor: pointer;
}

.new-step-container .new-step-content .new-step .step-params .base .markers {
  display: flex;
  flex-direction: row;
  gap: 10px;
  user-select: none;
  cursor: pointer;
  height: 25px;
  align-items: center;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .markers:last-of-type {
  margin-bottom: 20px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .markers
  .hs-id {
  background: rgba(100, 100, 100, 0.2);
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .markers
  .marker-name {
  min-width: 56%;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker {
  width: 60%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker
  .inputs {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker
  .inputs
  input[type="text"] {
  border-bottom: solid 1px #0072FF6c;
  transition: 0.2s;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker
  .inputs
  input[type="text"]:focus {
  border-bottom: solid 2px var(--primary-color);
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker
  .inputs
  label {
  margin: 0;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker
  .submit-changes {
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker
  .submit-changes
  span {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker
  .submit-changes
  span:hover {
  background: rgba(210, 211, 210, 0.5);
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker
  .submit-changes
  .confirm {
  color: #148648;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .base
  .edit-marker
  .submit-changes
  .cancel {
  color: #580e1e;
}

.new-step-container .new-step-content .new-step .step-params .extra {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 30%;
}
.new-step-container .new-step-content .new-step .step-params .extra .documents,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements,
  .new-step-container .new-step-content .new-step .step-params .extra .tools {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 1px 8px #142e6e1a;
  border: 1px solid #0515244d;
  border-radius: 5px;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .title,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .title,
  .new-step-container .new-step-content .new-step .step-params .extra .tools .title {
  background: var(--primary-color);
  padding: 15px 20px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: white;
  font-size: 1.2rem;
  height: 50px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .current-measurements
  .no-docs,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .no-docs {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #0515244d;
  opacity: 0.4;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .current-measurements
  .no-docs
  .circle,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .no-docs
  .circle {
  border: 1px solid grey;
  padding: 15px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .document {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #0515244d;
  padding: 10px 20px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .document
  .info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .document
  .info
  .doc-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .document
  .info
  .doc-info
  .name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .document
  .info
  .doc-info
  .name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .document
  .info
  .doc-info
  .extras {
  display: flex;
  gap: 5px;
  font-size: 0.8rem;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .document
  .options {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .current-files
  .document
  .options
  .dots {
  cursor: pointer;
  position: relative;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .upload,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .upload {
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .upload
  .import-doc,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .upload
  .new-measurement {
  border: 1px solid #0515241f;
  border-radius: 25px;
  padding: 10px 40px;
  color: var(--primary-color);
  text-transform: uppercase;
  transition: 0.2s;
  cursor: pointer;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .documents
  .upload
  .import-doc:hover,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .upload
  .new-measurement:hover {
  background: var(--primary-color);
  color: white;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create {
  padding: 5px;
  max-height: 300px;
  overflow-y: auto;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .add-title {
  font-weight: bold;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create::-webkit-scrollbar {
  width: 5px;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: #f2f5f8;
  gap: 15px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  div
  label {
  margin: 0;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  div
  input,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  div
  select,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  div
  textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #cad6e2;
  border-radius: 5px;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  div
  textarea {
  resize: none;
  height: 100px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  div
  input:focus,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  div
  select:focus,
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  div
  textarea:focus {
  outline: none;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .current-measurements {
  display: flex;
  flex-direction: column;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .current-measurements
  .measurement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #0515244d;
  padding: 10px 20px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .current-measurements
  .measurement
  .info
  .value {
  font-weight: bold;
  color: var(--primary-color);
  font-size: 1.2rem;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .current-measurements
  .measurement
  .info
  .extras {
  display: flex;
  gap: 10px;
  text-transform: capitalize;
  font-size: 0.8rem;
}
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .current-measurements
  .measurement
  .options
  .dots {
  cursor: pointer;
  position: relative;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  .switch
  input {
  opacity: 0;
  width: 0;
  height: 0;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  input:checked
  + .slider {
  background-color: var(--primary-color);
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  input:focus
  + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  input:checked
  + .slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  .slider.round {
  border-radius: 34px;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  .slider.round:before {
  border-radius: 50%;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  .slider.round:before {
  border-radius: 50%;
}

.new-step-container
  .new-step-content
  .new-step
  .step-params
  .extra
  .measurements
  .create
  .create-measurement
  .data
  .notes
  .container {
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
  gap: 10px;
}
</style>